<section class="heroHomeEnley">
  <div class="logo">
    <img
      id="imgHome"
      class="logoStyle"
      src="assets/img/enley-servicios-legales-online.png"
      alt="Consulta abogados online"
      height="66"
      width="193"
    />
  </div>
  <h1 class="titlePadding title homeTitle">
    La solución integral a tus problemas legales
  </h1>
  <h3 class="titlePadding">
    Tenemos un equipo de más de 40 abogados colegiados expertos en distintas
    áreas del derecho para ayudarte donde y cuando quieras.
  </h3>
  <div class="button-container">
    <button (click)="scrollToServices()">MÁS INFORMACIÓN</button>
  </div>
  <div class="headerImageCentered nubes overlay">
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      src="assets/img/home_nubes.html"
    ></iframe>
  </div>
  <div class="headerImageCentered pc-version overlay">
    <iframe
      width="520px"
      height="534px"
      frameborder="0"
      src="assets/img/home_izda.html"
    ></iframe>
    <iframe
      width="450px"
      height="534px"
      frameborder="0"
      src="assets/img/home_dcha.html"
    ></iframe>
  </div>
  <div class="headerImageCentered phone-version">
    <div class="clouds-phone">
      <iframe
        frameborder="0"
        width="1810px"
        height="210px"
        scrolling="no"
        src="assets/img/home_nubes.html"
      ></iframe>
    </div>
    <iframe
      class="phone-image"
      width="325px"
      height="380px"
      frameborder="0"
      src="assets/img/home_phone.html"
    ></iframe>
  </div>
</section>
