<div class="floating-whatsapp">
  <span class="whatsapp-text">
    <b>{{ whatsapp }}</b>
  </span>
  <a
    *ngIf="whatsapp"
    class="image-link"
    href="https://wa.me/{{ whatsapp }}"
    target="_blank"
  >
    <img
      class="image-whatsapp"
      src="assets/img/whatsapp-logo.png"
      alt="whatsapp"
      width="50px"
      height="50px"
    />
  </a>
</div>
