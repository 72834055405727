import { Component, Input } from '@angular/core';
import { ProductCategoryGenericProductModalComponent } from '../../generic-product-modal/generic-product-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-productcategory-agency-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
})
export class ProductCategoryAgencyTableComponent {
  @Input() priceByTier = [];
  @Input() featuresByTier = {};
  @Input() tableFooterType = '';

  constructor(private dialog: MatDialog) {}

  selectedTier = 'starter';

  getKeys() {
    return Object.keys(this.featuresByTier);
  }

  getPriceByTier(tierName) {
    const tier = this.priceByTier.find((item) => item.name === tierName);
    return tier ? tier.price : null;
  }

  openGenericProductModal() {
    //TODO: ADD PRODUCT
    this.dialog.open(ProductCategoryGenericProductModalComponent, {
      panelClass: 'dialog-with-overflow',
      maxWidth: '100vw',
      maxHeight: '70vh',
      data: { product: null },
    });
  }

  tableInfo(feature, tier: String) {
    if (this.tableFooterType === 'autonomos') {
      if (tier == 'starter') {
        if (feature.includes('Facturas')) {
          return '(10/mes)';
        } else if (feature.includes('los modelos de impuestos trimestrales')) {
          return '(1 modelo)';
        }
      }
      if (tier == 'pro') {
        if (feature.includes('Facturas')) {
          return '(100/mes)';
        } else if (feature.includes('los modelos de impuestos trimestrales')) {
          return '(2 modelos)';
        }
      }
      if (tier == 'premium') {
        if (feature.includes('Facturas')) {
          return '(Ilimitadas)';
        } else if (feature.includes('los modelos de impuestos trimestrales')) {
          return '(Todos)';
        }
      }
    }

    if (this.tableFooterType === 'autonomos') {
      if (this.featuresByTier[feature].includes('premium')) {
        if (feature.includes('Facturas')) {
          return '(Ilimitadas)';
        } else if (feature.includes('los modelos de impuestos trimestrales')) {
          return '(Todos)';
        }
      }
    }

    return '';
  }
}
