import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-faqs-home',
  templateUrl: './faqs-home.component.html',
  styleUrls: ['./faqs-home.component.css'],
})
export class FaqsHomeComponent implements OnInit {
  @Input() public backgroundColor: string = '';
  @Input() faqs: any[];
  @Input() public phone_number: string = '';

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.phone_number != '') {
      this.phone_number = this.phone_number.toString();
      this.phone_number.replace('34', '');
    }
    this.faqs.forEach((faq) => {
      const updatedHtml = faq.text_html.replace(
        '{{department_phone}}',
        this.phone_number
      );

      faq.text_html = this.sanitizer.bypassSecurityTrustHtml(
        `<style>a { color: #00bed6!important;}a:hover { color: #00bed6!important; }</style>` +
          updatedHtml
      );
    });
  }
}
