import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, retry, switchMap} from 'rxjs/operators';
import {
  CNAE,
  JuridicalPerson,
  ConstitutionPerson,
  FilterCNAE,
  FilterIAE,
  IAE,
  JuridicalPersonTemp,
  Company,
} from '../models/constitution';
import {Contrato} from '../models/contrato';
import {PurchaseAgreement} from '../models/purchase-agreement';
import {ContratoService} from './contrato.services';
import {globalApi} from './global';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConstitutionService {
  private url: string;

  constructor(
    private http: HttpClient,
    private contractService: ContratoService,
    private authService: AuthService
  ) {
    this.url = globalApi.url;
  }

  postCompanyConstitution(
    contractId: number,
    companyConstitution: JuridicalPerson,
    productId
  ): Observable<JuridicalPerson> {
    if (contractId) {
      return this.http
        .post<JuridicalPerson>(
          this.url + 'juridical_person/',
          companyConstitution
        )
        .pipe(
          switchMap((resp: JuridicalPerson) => {
            let contract = new Contrato();
            contract.juridical_person_id = resp.id;
            contract.product_id = productId;

            return this.contractService
              .patchContrato(contractId, contract)
              .pipe(
                map(() => {
                  return resp;
                })
              );
          })
        );
    } else {
      return this.http.post<JuridicalPerson>(
        this.url + 'juridical_person/',
        companyConstitution
      );
    }
  }

  patchCompanyConstitution(
    companyConstitution: JuridicalPerson
  ): Observable<JuridicalPerson> {
    return this.http.patch<JuridicalPerson>(
      this.url + 'juridical_person/' + companyConstitution.id + '/',
      companyConstitution
    );
  }

  postCompanyConstitutionCertificate(
    contractId: number,
    certificate: File
  ): Observable<JuridicalPerson> {
    let fd = new FormData();

    fd.append('file', certificate, certificate.name);
    fd.append('type', certificate.type);

    return this.http.post<JuridicalPerson>(
      this.url + 'contract/' + contractId + '/juridical_person/certificate/',
      fd
    );
  }

  putCompanyConstitution(
    companyConstitution: JuridicalPerson
  ): Observable<JuridicalPerson> {
    return this.http.put<JuridicalPerson>(
      this.url + 'juridical_person/' + companyConstitution.id + '/',
      companyConstitution
    );
  }

  putLogo(
    companyConstitution: JuridicalPerson,
    fileToUpload?: File
  ): Observable<JuridicalPerson> {
    const endpoint =
      this.url + 'juridical_person/' + companyConstitution.id + '/';
    let headers = new HttpHeaders().set('Accept-Language', 'en');

    var formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('logo', fileToUpload, fileToUpload.name);
    }
    formData = this.createFormData(companyConstitution, formData);

    return this.http.patch<JuridicalPerson>(endpoint, formData, {
      headers: headers,
    });
  }

  private createFormData(object, formData) {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] instanceof Object && !(object[key] instanceof Date)) {
          for (let nestedKey in object[key]) {
            if (object[key][nestedKey] != undefined) {
              formData.append(key + '.' + nestedKey, object[key][nestedKey]);
            }
          }
        } else if (object[key] instanceof Date) {
          formData.append(key, object[key].toISOString());
        } else {
          if (
            object[key] != undefined &&
            key != 'logo' &&
            key != 'social_denomination_1' &&
            key != 'needs_roi' &&
            key != 'is_salable' &&
            key != 'name'
          ) {
            formData.append(key, object[key]);
          }
        }
      }
    }
    return formData;
  }
  getCompanyConstitutions(customer_id?: number): Observable<JuridicalPerson[]> {
    let params = new HttpParams();
    if (customer_id) {
      params = params.set('customer_id', customer_id);
    }
    return this.http.get<JuridicalPerson[]>(this.url + 'juridical_person/', {
      params: params,
    });
  }

  getCompanyConstitution(juridicalId): Observable<JuridicalPerson> {
    if (juridicalId === undefined) return of(null);
    return this.http.get<JuridicalPerson>(
      this.url + 'juridical_person/' + juridicalId + '/'
    );
  }

  getCompanyConstitutionLogo(juridicalId) {
    return this.http.get<JuridicalPerson>(
      this.url + 'juridical_person_logo/' + juridicalId + '/'
    );
  }

  postConstitutionPerson(
    contractId: number,
    constitutionPerson: ConstitutionPerson
  ): Observable<ConstitutionPerson> {
    return this.http.post<ConstitutionPerson>(
      this.url + 'constitution_person/',
      constitutionPerson
    );
  }

  patchConstitutionPerson(
    constitutionPerson: ConstitutionPerson
  ): Observable<ConstitutionPerson> {
    return this.http.patch<ConstitutionPerson>(
      this.url + 'constitution_person/' + constitutionPerson.id + '/',
      constitutionPerson
    );
  }

  getConstitutionPersons(juridicalId): Observable<ConstitutionPerson[]> {
    let params = new HttpParams();
    if (juridicalId) {
      params = params.set('juridical_id', juridicalId);
    }

    return this.http.get<ConstitutionPerson[]>(
      this.url + 'constitution_person/',
      {params: params}
    );
  }

  createCompany(company: Company): Observable<Company> {
    return this.http.post<any>(this.url + 'company_librebor/', company);
  }

  getCompany(
    person: ConstitutionPerson | JuridicalPersonTemp
  ): Observable<Company[]> {
    if (person.CIF) {
      return this.http.get<Company[]>(
        this.url + 'company_librebor/juridical/' + person.id + '/'
      );
    } else {
      return this.http.get<Company[]>(
        this.url + 'company_librebor/constitution/' + person.id + '/'
      );
    }
  }

  deleteConstitutionPerson(
    constitution_person: ConstitutionPerson
  ): Observable<any> {
    return this.http.delete<any>(
      this.url + 'constitution_person/' + constitution_person.id + '/'
    );
  }

  postJuridicalPersonTemp(
    juridicalPersonTemp: JuridicalPersonTemp
  ): Observable<JuridicalPersonTemp> {
    return this.http.post<JuridicalPersonTemp>(
      this.url + 'juridical_person_temp/',
      juridicalPersonTemp
    );
  }

  patchJuridicalPersonTemp(
    juridicalPersonTemp: JuridicalPersonTemp
  ): Observable<JuridicalPersonTemp> {
    return this.http.patch<JuridicalPersonTemp>(
      this.url + 'juridical_person_temp/' + juridicalPersonTemp.id + '/',
      juridicalPersonTemp
    );
  }

  putJuridicalPersonTemo(
    juridicalPersonTemp: JuridicalPersonTemp
  ): Observable<JuridicalPersonTemp> {
    return this.http.put<JuridicalPersonTemp>(
      this.url + 'juridical_person_temp/' + juridicalPersonTemp.id + '/',
      juridicalPersonTemp
    );
  }

  getJuridicalPersonsTemp(juridicalId): Observable<JuridicalPersonTemp[]> {
    let params = new HttpParams();
    if (juridicalId) {
      params = params.set('juridical_id', juridicalId);
    }
    return this.http.get<JuridicalPersonTemp[]>(
      this.url + 'juridical_person_temp/',
      {params: params}
    );
  }

  deleteJuridicalPersonTemp(
    juridicalPersonTemp: JuridicalPersonTemp
  ): Observable<any> {
    return this.http.delete<any>(
      this.url + 'juridical_person_temp/' + juridicalPersonTemp.id + '/'
    );
  }

  getCNAES(filter?: FilterCNAE): Observable<CNAE[]> {
    if (filter) {
      return this.http.get<CNAE[]>(this.url + 'cnae/?query=' + filter.query);
    } else {
      return this.http.get<CNAE[]>(this.url + 'cnae/');
    }
  }

  getIAE(filter?: FilterIAE): Observable<IAE[]> {
    if (filter) {
      return this.http.get<IAE[]>(this.url + 'iae/?query=' + filter.query);
    } else {
      return this.http.get<IAE[]>(this.url + 'iae/');
    }
  }

  getJuridicalPersonEmail(
    juridicalPersonId: number,
    type: string
  ): Observable<any> {
    const params = {type: type};
    return this.http.get<any>(
      this.url + 'juridical_person/' + juridicalPersonId + '/email/',
      {params}
    );
  }

  createJuridicalPersonEmail(
    juridicalPersonId: number,
    email: string,
    type: string
  ): Observable<any> {
    return this.http.post<any>(
      this.url + 'juridical_person/' + juridicalPersonId + '/email/ ',
      {email: email, type: type}
    );
  }

  updateJuridicalPersonEmail(
    juridicalPersonEmailId: number,
    email: string,
    type: string
  ): Observable<any> {
    return this.http.put<any>(
      this.url + 'juridical_person_email/' + juridicalPersonEmailId + '/',
      {email: email, type: type}
    );
  }

  getConstitutionRecommendations(juridical_id: number): Observable<any> {
    let headers = new HttpHeaders().set(
      'Token',
      this.authService.getJwtToken()
    );
    return this.http.get(
      'https://api.enley.com/enley-ai/predict/' + juridical_id,
      {
        headers: headers,
      }
    );
  }
}
