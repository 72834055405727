<section [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''">
  <!-- PC version -->
  <div class="content-wrapper-home desktop-view">
    <h2 class="title-article-header">Artículos de nuestro blog</h2>
    <div class="articles-container-home">
      <div *ngFor="let article of articles" class="article-card-home">
        <div class="article-category-time-home">
          <span class="article-category-home"
            >{{ article.category | uppercase }} |</span
          >
          <span class="article-time-home">{{ article.minutes }}</span>
        </div>
        <a href="{{ article.link }}">
          <div>
            <p class="article-title-home">
              {{ article.title }}
              <span class="article-description-home">{{
                truncate(article.description)
              }}</span>
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>

  <!-- Phone version -->
  <div class="mobile-view" [ngStyle]="{'background-color': backgroundColor}">
    <h2 class="title-article-header">Artículos de nuestro blog</h2>
    <div
      #carouselContainer
      class="carousel-container-1"
      (swipeleft)="nextSlide()"
      (swiperight)="previousSlide()"
    >
      <div
        *ngFor="let article of articles; let i = index"
        class="carousel-item-1"
      >
        <div class="article-card-home">
          <div class="article-category-time-home">
            <span class="article-category-home">{{ article.category }} |</span>
            <span class="article-time-home">{{ article.minutes }}</span>
          </div>
          <a href="{{ article.link }}">
            <div>
              <p class="article-title-home">
                {{ article.title }}
                <span class="article-description-home">{{
                  truncate(article.description)
                }}</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="carousel-dots-1">
      <span class="dot-1" #dot (click)="currentSlide(1)"></span>
      <span class="dot-1" #dot (click)="currentSlide(2)"></span>
      <span class="dot-1" #dot (click)="currentSlide(3)"></span>
      <span class="dot-1" #dot (click)="currentSlide(4)"></span>
      <span class="dot-1" #dot (click)="currentSlide(5)"></span>

      <span class="dot-1" #dot (click)="currentSlide(6)"></span>
    </div>
  </div>
</section>
