<section [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''">
  <div>
    <h2>¿Por qué elegir Enley?</h2>
    <h5>
      Unimos el conocimiento de abogados expertos con la rapidez y comodidad de
      la tecnología.
    </h5>
    <app-why-enley-reason></app-why-enley-reason>
  </div>
</section>
