<app-header-home></app-header-home>
<app-home-reviews
  backgroundColor="gray"
  title="Más de 10.000 personas ya han confiado en nosotros"
></app-home-reviews>
<div class="white-background"></div>
<app-why-enley></app-why-enley>
<app-can-do backgroundColor="gray"></app-can-do>
<app-how-it-works></app-how-it-works>
<app-services id="servicesComponent" backgroundColor="gray"></app-services>
<app-articles-home [articles]="articles"></app-articles-home>
<app-faqs-home backgroundColor="gray" [faqs]="faqs"></app-faqs-home>
