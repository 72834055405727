<div class="container" [id]="slugify(subcategory.name)">
  <div class="header" #headerRef (click)="expandCollapse()">
    <div class="icon-and-title">
      <h1>{{ subcategory.name }}</h1>
    </div>
    <img
      [@rotateInOut]="animationState"
      src="assets/img/basic-arrow.svg"
      class="dropdown-arrow"
    />
  </div>
  <hr />
  <div [@slideInOut]="animationState" class="content">
    @if (subcategory.name === 'Laboral') {
    <app-productcategory-agency-laboral
      style="width: 100%; display: block"
    ></app-productcategory-agency-laboral>
    } @else if (subcategory.name === 'Autónomos') {
    <app-productcategory-agency-autonomos
      style="width: 100%; display: block"
    ></app-productcategory-agency-autonomos>
    } @else if (subcategory.name === 'Sociedades') {
    <app-productcategory-agency-sociedades
      style="width: 100%; display: block"
    ></app-productcategory-agency-sociedades>
    } @else {
    <app-productcategory-product
      [products]="subcategory.products"
    ></app-productcategory-product>
    }
  </div>
</div>
