import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {ProductSubCategory} from 'src/app/models/productcategory';
import {Product} from 'src/app/models/products';
import {MatDialog} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {Router, ActivatedRoute} from '@angular/router';
import {UsuarioServices} from 'src/app/services/usuario.services';
import {AuthService} from 'src/app/services/auth.service';
import {User} from 'src/app/models/user';
import {Location, ViewportScroller} from '@angular/common';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {RotateAnimation, SlideInOutAnimation} from '../../../animations';
import {ProductCategoryGenericProductModalComponent} from '../generic-product-modal/generic-product-modal.component';

@Component({
  selector: 'app-productcategory-product',
  templateUrl: './product.component.html',
  styleUrl: './product.component.css',
  providers: [UsuarioServices, AuthService],
  animations: [SlideInOutAnimation, RotateAnimation],
})
export class ProductSubcategoryComponent implements OnInit {
  @Input() products: Product = null;

  @ViewChild('headerRef') header: ElementRef;
  public isLoggedIn: boolean = false;
  public user: User = undefined;
  public htmlContent: SafeHtml = null;
  public svgContent: any = null;
  public isOpened: boolean = false;

  @Output() expandedCollapsed = new EventEmitter<Boolean>();

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userService: UsuarioServices,
    private authService: AuthService,
    private viewportScroller: ViewportScroller
  ) {}

  animationState = 'out';

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.getUser();
    }
  }

  getUser() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  handleProductClick(event: Event, product: Product) {
    event.preventDefault();
    if (product.url) {
      this.router.navigate([product.url]);
    } else {
      this.dialog.open(ProductCategoryGenericProductModalComponent, {
        panelClass: 'dialog-with-overflow',
        maxWidth: '100vw',
        maxHeight: '70vh',
        data: {product: product, user: this.user},
      });
    }
  }

  expandCollapse() {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
    this.isOpened = !this.isOpened;

    if (this.animationState === 'out') return;

    const headerElement = this.header.nativeElement;
  }
}
