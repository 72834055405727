import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.css'],
})
export class HeaderHomeComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  scrollToServices() {
    if (isPlatformBrowser(this.platformId)) {
      const servicesElement = document.getElementById('servicesComponent');
      if (servicesElement) {
        servicesElement.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
