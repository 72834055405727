<div class="container-products">
  <div class="content">
    <ul>
      <li *ngFor="let product of products" class="product-card">
        <div class="product-header">
          <span class="product-title">{{
            product.product_description.name
          }}</span>
          <span
            class="product-price"
            [innerHTML]="product.product_description.price_text"
          ></span>
        </div>
        <span class="product-description">{{
          product.product_description.description
        }}</span>

        <a class="product-link" (click)="handleProductClick($event, product)">{{
          product.product_description.cta
        }}</a>
      </li>
    </ul>
  </div>
</div>
