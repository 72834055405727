<div class="parent-container">
  <section [ngClass]="backgroundColor == 'gray' ? 'background-gray' : ''">
    <div class="header-service">
      <h2>Hablamos tu idioma</h2>
      <h5>
        Sabemos que el lenguaje legal es complejo y en ocasiones difícil de
        entender. Creemos que es fundamental que entiendas en qué consiste y qué
        está pasando en tu trámite legal, por eso hablamos tu idioma, para
        hacértelo sencillo.
      </h5>
      <div>
        <button
          [ngClass]="forYou ? 'selected' : ''"
          class="for-you"
          (click)="updateYouServices()"
        >
          Para ti
        </button>
        <button
          [ngClass]="forBusiness ? 'selected' : ''"
          class="company"
          (click)="updateCompanyServices()"
        >
          Para tu empresa
        </button>
      </div>
    </div>
    <app-services-for-you
      [items$]="itemsForYou$"
      *ngIf="forYou"
      [@servicesTypesOpacity]="show ? 'show' : 'hide'"
    ></app-services-for-you>
    <app-services-for-you
      *ngIf="forBusiness"
      [items$]="itemsForBusiness$"
      [@servicesTypesOpacity]="show ? 'show' : 'hide'"
    ></app-services-for-you>
  </section>
  <div class="white-background"></div>
</div>
