import { ProductsID } from './contrato';
import { Department } from './department';
import { FieldType } from './tracking';

export class ProductDescription {
  home_how_it_works_end: string;
  home_how_it_works_subtitle: string;
  title_email: string;
  paragraph_1_email: string;
  paragraph_2_email: string;
  button_1_email: string;
  button_2_email: string;
  subject_email: string;
  description_button_title: string;
  home_tier_html: string;
  home_options_html: string;
  home_tier_basic_html: string;
  home_tier_advanced_html: string;
  home_tier_premium_html: string;
  home_list_why_html: string;
  category_id: number;
  constructor(
    public id?: number,
    public product_id?: number,
    public name?: string,
    public title_html?: string,
    public home_button_action?: string,
    public description_html?: string,
    public color_html?: string,
    public thank_you_title_text?: string,
    public thank_you_button_text?: string,
    public thank_you_button_url?: string,
    public thank_you_button_title_text?: string,
    public pre_study_title_text?: string,
    public pre_study_button_text?: string,
    public pre_study_button_url?: string,
    public value?: string,
    public title?: string,
    public meta_description?: string,
    public home_image_alt?: string,
    public home_title?: string,
    public home_paragraph?: string,
    public state?: string,
    public product_description_image_alt?: string,
    public price_text?: any,
    public image?: string,
    public image_html?: string,
    public image_js?: string
  ) {}
}

export class Product {
  home_how_it_works_end: string;
  home_how_it_works_subtitle: string;
  home_paragraph: string;
  home_title: string;
  title: string;
  meta_description: string;
  home_image: string;
  home_image_file: File;
  home_image_mobile: string;
  home_image_mobile_file: File;
  home_button_action: string;
  image_width: number;
  image_height: number;
  home_image_alt: string;
  form_type: number;
  scheduled_payment: string;
  product_description_image: string;
  product_description_image_file: File;
  product_description_image_alt: string;
  product_home_sections: Array<ProductHomeSection>;
  included: Array<string>;
  extras: boolean;
  department_id: number;
  person_type_needed: string;
  allowed_to_sign: boolean;
  department: Department;
  previous_form: boolean;
  description: string;

  constructor(
    public id?: number,
    public name?: string,
    public url?: string,
    public title_html?: string,
    public description_html?: string,
    public color_html?: string,
    public price?: number,
    public price_without_tax?: number,
    public contract_fields?: FieldType[],
    public thank_you_title_text?: string,
    public thank_you_subtitle_text?: string,
    public thank_you_1_text?: string,
    public thank_you_1_sub_text?: string,
    public thank_you_2_text?: string,
    public thank_you_3_text?: string,
    public thank_you_1_image?: string,
    public thank_you_2_image?: string,
    public thank_you_3_image?: string,
    public thank_you_closing_text?: string,
    public thank_you_button_text?: string,
    public thank_you_button_url?: string,
    public thank_you_button_title_text?: string,
    public pre_study_title_text?: string,
    public pre_study_button_text?: string,
    public pre_study_button_url?: string,
    public value?: string,
    public product_description?: ProductDescription,
    public has_form?: boolean,
    public title_trans?: string
  ) {}

  public static getProductType(productID: number, product?: Product): string {
    if (productID == ProductsID.divorce_product_id) {
      return 'divorcio';
    } else if (productID == ProductsID.inheritance_product_id) {
      return 'herencia';
    } else if (productID == ProductsID.creditors_arrangement_id) {
      return 'concurso de acreedores';
    } else if (productID == ProductsID.down_payment_id) {
      return 'contrato de arras';
    } else if (productID == ProductsID.creditors_pre_arrangement_id) {
      return 'preconcurso de acreedores';
    } else if (productID == ProductsID.buy_sell_contract) {
      return 'contrato de compraventa';
    } else if (productID == ProductsID.limited_society) {
      return 'sociedad';
    } else if (product && product.product_description.name) {
      return product.product_description.name.toLocaleLowerCase();
    }
  }
}

export function isProductVisible(product: Product): boolean {
  return product.url != null && !product.url.includes('landing');
}

export class ProductHomeSection {
  title: string;

  constructor(public inner: string) {}
}

export class Faqs {
  id: number;
  title: string;
  text_html: string;
  order: number;
  showDetail: boolean;
  new: boolean;
  language: string;

  constructor() {
    this.language = 'es';
  }
}

export class ProductPrice {
  constructor(
    public id?: number,
    public product_id?: number,
    public html_post_payment_body_text?: string,
    public html_post_payment_button_text?: string,
    public html_post_payment_image?: string,
    public html_post_payment_image_file?: File,
    public html_post_payment_button_url?: string,
    public html_post_payment_subtitle_text?: string,
    public html_post_payment_title_text?: string,
    public html_text?: string,
    public html_title?: string,
    public html_button_text?: string,
    public html_button_url?: string,
    public html_concept?: string,
    public step?: number,
    public price?: string,
    public price_without_tax?: string,
    public show_detail?: boolean,
    public new_payment?: boolean,
    public end_date?: Date,
    public start_date?: Date,
    public updated?: boolean
  ) {}
}
export class StepHome {
  description: string;
  image: string;
  image_file: File;
  alt: string;
  order: string;
  showDetail: boolean;
  new: boolean;
  language: string;
  id: number;

  constructor() {
    this.showDetail = false;
    this.language = 'es';
  }
}
