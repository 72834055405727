import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Department } from '../models/department';
import { Product } from '../models/products';
import { globalApi } from './global';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  public url: string;

  public departments$: BehaviorSubject<Department[]> = new BehaviorSubject<
    Department[]
  >([]);

  constructor(private http: HttpClient) {
    this.url = globalApi.url;
    this.getDepartments().subscribe();
  }

  getDepartments(): Observable<Department[]> {
    let url = this.url + 'department/';
    return this.http.get<Department[]>(url).pipe(
      map((resp) => {
        this.setDepartments(resp);
        return resp;
      })
    );
  }

  getDepartment(pk: number): Observable<Department> {
    let url = this.url + 'department/' + pk;
    return this.http.get<Department>(url);
  }

  setDepartments(departments) {
    this.departments$.next(departments);
  }
}
